import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@creditas/typography'
import { Container } from '@creditas/layout'

const NotFoundPage = () => {
  const { t } = useTranslation()

  return (
    <Container maxWidth="sm">
      <Typography variant="h3">{t('notFound:headings.title')}</Typography>
      <Typography variant="body1">
        {t('notFound:phrases.page_not_found')}
      </Typography>
    </Container>
  )
}

export default NotFoundPage
